@media (max-width: 1023px) {
  .navbar .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.navbar {
  box-shadow: 0 1px 8px 2px rgba(0, 0, 0, 0.5);
}

.content ul.squared-list {
  list-style-type: square;
}

.button,
.input {
  font-family: 'Mukta', sans-serif;
}

.hero.is-fullheight.is-primary.is-bold {
  min-height: calc(100vh - 3.25rem);
  margin-top: 3.25rem;
  background-image: url('/assets/images/AdobeStock_238255062.jpeg');
  background-size: cover;
  background-position: right;
  background-repeat: no-repeat;
  @media (max-width: 768px) {
    min-height: unset;
    .hero-body {
      padding-top: 5.25rem;
      padding-bottom: 2rem;
    }
  }
  .hero-body {
    background-color: rgba(246, 160, 9, .7);
    .intro {
      @media (max-width: 768px) {
        display: flex;
        flex-direction: column-reverse;
        .intro-logo .colums {
          display: flex;
        }
      }
      &-logo {
        margin-top: auto;
        .subtitle {
          margin-top: 2rem;
        }
      }
      &-panel {
        border-radius: .375rem;
        padding: 1.5rem;
        box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, .1), 0 0 0 1px rgba(10, 10, 10, .02);
      }
      &-text {
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.5rem;
        margin: 0 0 1rem;
      }
    }
  }
}

.navbar {
  .navbar-item {
    .login {
      display: flex;
      align-items: center;
      color: #4A4A4A;
      &:hover {
        color: #2b3a8c;
      }
      .fa-user {
        margin-right: .5rem;
      }
    }
  }
}

.button.is-primary {
  background-color: #f6a009;
  border-color: transparent;
  color: #fff;
  &:hover {
    background-color: #ea9809;
  }
  &:disabled {
    background-color: rgba(#f6a009, 0.8);
  }
}

section.hero.pricing {
  background-image: url('/assets//images/login-bg.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  .hero-body {
    background-color: rgba(44, 26, 61, .7);
    .subtitle {
      color: white;
      text-align: center;
    }
  }
  .pricing-card {
    max-width: 15rem;
    margin: auto;
    border-radius: .375rem;
    text-align: center;
    line-height: 1;
    &-header {
      padding: 1rem .5rem;
      background-color: #f6a009;
      color: white;
      div:first-child {
        font-weight: bold;
        font-size: 2rem;
        span.small {
          font-size: 60%;
        }
        span:not(.small) {
          visibility: hidden;
        }
      }
      div:last-child {
        font-size: 1.25rem;
      }
    }
    &-content {
      padding: 1rem .5rem;
      background-color: white;
      div:first-child {
        font-weight: bold;
        font-size: 4rem;
        .small {
          font-size: 30%;
          &-top {
            position: relative;
            top: -1.5rem;
            font-size: 40%;
          }
        }
      }
      div:last-child {
        font-size: .8rem;
        color: #5C5C5C;
      }
    }
  }
}

.section.screen {
  padding-bottom: 0;
  .columns {
    margin-bottom: -.5rem;
    @media (max-width: 767px) {
      display: flex;
      flex-direction: column-reverse;
    }
    .column {
      padding-bottom: 0;
      &:first-of-type {
        display: flex;
        align-items: flex-end;
        @media (max-width: 767px) {
          display: block;
        }
      }
      &:last-of-type {
        padding-bottom: 3rem;
      }
    }
  }
}

.section.premium {
  .columns {
    margin-left: 0;
    margin-right: 0;
    .column {
      padding: .75rem 0;
      .premium__block {
        height: calc(100% - 4rem);
        margin: 2rem 0;
        padding: 3rem;
        text-align: center;
        background-color: white;
        box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.02);
        .subtitle {
          color: rgb(42, 30, 59);
        }
        &__icon {
          color: rgb(42, 30, 59);
          font-size: 250%;
        }
      }
      &.center {
        z-index: 1;
        .premium__block {
          height: 100%;
          margin: 0;
          background-color: white;
          .subtitle {
            font-size: 150%;
          }
          p {
            font-size: 110%;
          }
          .premium__block__icon {
            font-size: 300%;
          }
        }
      }
    }
  }
}

.footer {
  a {
    color: #2b3a8c;
  }
}